export const WEB_TITLE = "ChartCrafter";
export const WEB_URL = window.location.hostname;

// env
export const PUBLICATIONSTATE =
  {
    localhost: "preview",
    "platform.chartcrafter.org": "live"
  }[WEB_URL] || "live";

export const STRAPI_URL =
  {
    localhost: "https://chartcrafter-cms-staging.herokuapp.com/api",
    "platform.chartcrafter.org": "https://chartcrafter-cms.herokuapp.com/api"
  }[WEB_URL] || "https://chartcrafter-cms.herokuapp.com/api";

// meta
export const META_IMAGE = WEB_URL + "/images/Logo CC_reg_color.svg";
export const META_IMAGE_ALT = `Logo of the ${WEB_TITLE}`;
export const META_TWITTER_SITE = "@chartcrafter";

export const DEFAULT_LARGE_IMAGE = "/images/default.webp";
export const DEFAULT_TINY_IMAGE = "/images/default.webp";

export const PAGINATION_SIZE = 8;
