<template>
  <div>
    <v-app-bar
      app
      class="navigation"
      :class="navigationClass"
      :elevation="appBarElevation"
      :color="appBarColor"
      light
      height="auto"
      v-scroll="onScroll"
      ref="appBar"
    >
      <Logo class="logo-img" />
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        class="d-flex d-sm-none"
        aria-label="navigation drawer"
        role="navigation"
        @click="openMenu"
      />
      <div class="d-none d-sm-flex">
        <v-hover v-slot="{ hover }" v-for="(link, index) in links" :key="index">
          <v-btn
            :to="link.path"
            plain
            :ripple="false"
            :class="hover ? 'primary--text' : 'transparent'"
          >
            {{ link.name }}
          </v-btn>
        </v-hover>
      </div>

      <v-spacer class="d-none d-sm-flex" />

      <div class="d-none d-sm-flex">
        <LocaleSwitch v-if="FEATURE_FLAGS.internationalization" />
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="isMenuOpen" app right temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="(link, index) in links"
            :key="index"
            :to="link.path"
          >
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="FEATURE_FLAGS.internationalization">
            <LocaleSwitch />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import LocaleSwitch from "@/components/LocaleSwitch";
import Logo from "@/components/Logo";
import { FEATURE_FLAGS } from "@/enums/featureFlags";

const halfTransparentWhite = "rgba(255,255,255,0.6)";

export default {
  name: "NavigationBar",
  components: { LocaleSwitch, Logo },
  data() {
    return {
      appBarColor: halfTransparentWhite,
      navigationClass: "navigation",
      appBarElevation: 0,
      isMenuOpen: false,
      FEATURE_FLAGS: FEATURE_FLAGS,
      links: [
        { name: this.$t("sections.patterns"), path: "/patterns" },
        { name: this.$t("sections.editor"), path: "/editor" },
        { name: this.$t("sections.workshop"), path: "/workshop" }
      ]
    };
  },
  methods: {
    onScroll() {
      const appBarHeight = this.$refs.appBar.$el.clientHeight;
      if (
        document.body.scrollTop > appBarHeight ||
        document.documentElement.scrollTop > appBarHeight
      ) {
        this.appBarElevation = 12;
        this.appBarColor = "white";
        this.navigationClass = "navigation-elevated";
      } else {
        this.appBarElevation = 0;
        this.appBarColor = halfTransparentWhite;
        this.navigationClass = "navigation";
      }
    },
    openMenu() {
      this.isMenuOpen = true;
    }
  }
};
</script>

<style scoped>
.navigation-elevated .v-btn.v-size--default,
.navigation .v-btn.v-size--default {
  font-size: 1.1rem;
}

.navigation,
.navigation-elevated {
  padding: 0.25rem 0rem;
  z-index: 3;
}
.v-navigation-drawer--temporary,
.v-navigation-drawer {
  z-index: 99;
}

.navigation .logo-img {
  width: 6.25rem;
}
.navigation-elevated .logo-img {
  width: 6.25rem;
}

@media (max-width: 1264px) {
  .navigation .v-btn.v-size--default,
  .navigation-elevated .v-btn.v-size--default {
    font-size: 1rem;
  }

  .navigation {
    padding: 0.25rem 0rem;
  }
  .navigation-elevated {
    padding: 0.25rem 0rem;
  }

  .navigation .logo-img,
  .navigation-elevated .logo-img {
    width: 6.25rem;
  }
}
@media (max-width: 960px) {
  .v-btn.v-size--default {
    font-size: 1rem;
  }

  .navigation,
  .navigation-elevated {
    padding: 0.25rem 0rem;
  }

  .navigation .logo-img,
  .navigation-elevated .logo-img {
    width: 6.25rem;
  }
}
@media (max-width: 600px) {
  .v-btn.v-size--default {
    font-size: 1rem;
  }

  .navigation,
  .navigation-elevated {
    padding: 0.25rem 0rem;
  }

  .navigation .logo-img,
  .navigation-elevated .logo-img {
    width: 6.25rem;
  }
}
</style>
