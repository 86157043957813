import Vue from "vue";
import VueI18n from "vue-i18n";
import { FEATURE_FLAGS } from "@/enums/featureFlags";

Vue.use(VueI18n);

const defaultLocale = "de";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getFallbackLocale() {
  return process.env.VUE_APP_I18N_LOCALE || defaultLocale;
}

function getDefaultLocale() {
  let languages = Object.getOwnPropertyNames(loadLocaleMessages());

  const cachedLocale = localStorage.getItem("locale");
  if (cachedLocale && languages.includes(cachedLocale)) return cachedLocale;

  const languagePartial = navigator.language.split("-")[0];
  if (languages.includes(languagePartial)) return languagePartial;

  return getFallbackLocale();
}

export default new VueI18n({
  locale: FEATURE_FLAGS.internationalization
    ? getDefaultLocale()
    : defaultLocale,
  fallbackLocale: getFallbackLocale(),
  messages: loadLocaleMessages()
});
